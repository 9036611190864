import * as React from "react";
import Button, { ButtonVariant } from "../components/Button";
import Layout from "../components/Layout";
import { useLayout } from "../components/Layout/layout-context";
import List from "../components/List";
import Section from "../components/Section";

const Creer = () => {
  const [setShowModal] = useLayout();

  return (
    <Layout
      title="Créer mon site web"
      metaTitle="Créer un site internet"
      description="Nous créons avec vous un site internet, performant et sur mesure, en adéquation avec vos besoins et ceux de votre cible"
    >
      <p className="mt-main">
        Vous avez un projet entrepreneurial ou êtes d’ores et déjà à la tête
        d’une société ? Nous réalisons des sites vitrine et des sites e-commerce
        pour toutes sortes de commerces. Détaillez-nous votre projet et nous
        travaillerons de concert avec vos ambitions pour mettre en place une
        application digitale ou un site web à la hauteur de vos attentes.
        <br />
        <br />
        Comme dans un projet de refonte, nous cherchons à offrir l’expérience
        adaptée à vos utilisateurs en hiérarchisant les informations et les
        parcours en fonction de votre stratégie et de vos objectifs.
      </p>

      <Section title="✨ Besoin d'une création ?">
        <p className="mt-main">
          Nous développons une solution en adéquation avec votre stratégie
          digitale en y injectant notre expertise et notre expérience du
          domaine.
        </p>

        <List
          data={[
            {
              title: `🎯  Analyse et estimation`,
              text: `La première phase de conception consiste à évaluer les besoins 
              de votre affaire et à définir les solutions fonctionnelles et graphiques à appliquer.
              À l’aide de la palette d’outils et de méthodes qui nous sont propres,
              nous améliorons significativement les éléments déterminants dans
              l’expérience des internautes sur votre site. De nombreux tests et
              indicateurs nous permettent de suivre en temps réel l’impact de nos
              transformations.`,
            },
            {
              title: `🎯  Benchmark et stratégie`,
              text: `Nous étudions l’ensemble des données à notre disposition ainsi que l’offre du marché  afin de définir le positionnement 
              à adopter face à  la concurrence.
              Nous ciblons votre audience et effectuons une collecte
              d'informations allant de la concurrence jusqu’à vos utilisateurs finaux. Le plus souvent, elle nécessite la conduite de focus 
              group, permettant d’intégrer dès le début du projet le regard de vos clients et affiner notre connaissance utilisateur à 
              travers une relation humaine concrète.`,
            },
            {
              title: `🎯  Définition de l’identité  de marque`,
              text: `Nous travaillons les contenus et le ton à  adopter pour s’adresser à votre audience. Nous faisons appel à des  outils
              exclusifs développés en interne comme des ateliers d’UX writing. L’ensemble de l’équipe est impliquée pour avoir une vision
              globale du projet.`,
            },
            {
              title: `🎯  Conception graphique`,
              text: `Notre équipe s’attelle à une réalisation graphique sur mesure et pertinente.`,
            },
            {
              title: `🎯 Tests utilisateur`,
              text: `Votre site est alors optimisé grâce à des mesures de la pertinence des éléments choisis. Différents tests utilisateurs
              seront mis en place jusqu’à obtenir une pleine satisfaction de vos utilisateurs. Vous constaterez alors une  prise de contact 
              amplifiée ou une augmentation concrète de vos ventes.`,
            },
          ]}
        />
      </Section>
      <Button
        variant={ButtonVariant.primary}
        text="Créer mon site"
        onClick={() => setShowModal(true)}
        className="mx-auto"
      />
    </Layout>
  );
};
export default Creer;
