import * as React from "react";
import { twMerge } from "tailwind-merge";
import Title, { TitleVariant } from "../Title";

interface Section {
  title: string;
  /** default h2 */
  titleVariant?: TitleVariant;
  className?: string;
}

const Section: React.FC<Section> = ({
  title,
  titleVariant,
  className,
  children,
}) => (
  <section className={twMerge("py-4 mt-main", className)}>
    <Title
      variant={titleVariant ?? TitleVariant.h2}
      text={title}
      className="text-center"
    />
    {children}
  </section>
);

export default Section;
