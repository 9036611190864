import * as React from "react";

interface List {
  data: Array<{
    title: string;
    text: string;
  }>;
}

const List = ({ data }: List) => (
  <ul className="text-base mt-main lg:text-xl">
    {data.map((el, id) => (
      <div key={"list-" + id}>
        <li className="mb-3">{el.title}</li>
        <li className="mb-8 text-main">{el.text}</li>
      </div>
    ))}
  </ul>
);

export default List;
